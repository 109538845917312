import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Select, Container, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import { GetNameChoose } from "../../funcUtils";

function HistoryBetAll() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [searched, setSearched] = useState("");
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [load, setLoad] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [total, setTotal] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const openPopup = (item) => {
        setTotal(item);
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data2.filter((row) => {
                const a =
                    row?.user?.username?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    searchedVal.toString().toLowerCase().includes(row?.user?.username?.toString().toLowerCase());
                const b = Number(row?.user?.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const c = row?.sanh?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const d = GetNameChoose(row?.state, row?.type, row?.sanh).toLowerCase().includes(searchedVal.toString().toLowerCase());
                return d || c || b || a;
            });
            setData(filteredRows);
            setPage(1);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
            setPage(1);
        }
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/all?date=${endDate}`, {})
            .then((res) => {
                localStorage.removeItem("data")
                setData2(res.data.data);
                setData(res.data.data);
            })
            .catch((res) => setData(null));
    }, []);
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };

    const selectHistory = (date) => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/all?date=${date}`, {})
            .then((res) => {
                setData(res.data.data);
                localStorage.removeItem("data");
                setData2(res.data.data);
                setLoading(false);
            })
            .then((res) => {
                setLoad(true);
                setLoading(false);
            });
    };
    /*const [table, setTable] = useState("");
    const handleChangeTable = (e) => {
        setTable(e.target.value);
        console.log(e.target.value);
        if (table !== "") {
            const filteredRows = data2.filter((row) => {
                return row.sanh.includes(table);
            });
            setData(filteredRows);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };

    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai",
        "Bến Tre"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];*/

    const [st, setSt] = useState(0);
    const handleChangeStatus = (e) => {
        if (ls?.status_bet === "Lose" || ls?.status_bet === "Pending") {
            if (e.target.value === "Win") {
                setSt(1);
            }
        } else {
            if (e.target.value === "Lose" || e.target.value === "Pending") {
                setSt(2);
            }
        }
        setLs((prevLs) => ({
            ...prevLs,
            status_bet: e.target.value
        }));
    };

    const handleSubmitLs = (e) => {
        e.preventDefault();
        const formData = {
            id: ls?._id,
            userId: ls?.user?._id,
            status_bet: e.target.status_bet.value,
            state: e.target.state.value,
            money: e.target.money.value,
            moneyuser: st == 1 ? e.target.moneythang.value : st == 2 ? Number(ls?.moneythang) * -1 : 0,
            moneythang: e.target.moneythang.value
        };
        axios
            .patch(`${process.env.REACT_APP_API_URL}/history`, formData)
            .then((res) => {
                setShow(false);
                swal("Cập nhật thành công", "", "success").then((value) => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                setShow(false);
                swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
            });
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            {isLoading ? (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            ) : null}
                            <Container maxWidth={false}>
                                <div className="container_set">Lịch sử giao dịch</div>
                                <div className="form_set">
                                    <Box>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <TextField
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                                placeholder="Tìm kiếm từ khóa"
                                                
                                            />
                                            <DatePicker
                                                maxDate={new Date()}
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                    selectHistory(date);
                                                }}
                                            />
                                            {/*<div>
                                                <span>Chọn trò chơi </span>
                                                <select onChange={handleChangeTable} value={table} id="table">
                                                    <option value="">Tất cả</option>
                                                    <option value="XSMB">XSMB</option>
                                                    <option value="XSMN">XSMN</option>
                                                    <option value="XSMT">XSMT</option>
                                                </select>
                                            </div>*/}
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>Số kỳ</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>User</TableCell>
                                                    {/*<TableCell sx={{padding:"10px"}}>ID User</TableCell>*/}
                                                    <TableCell sx={{ padding: "10px" }}>Số dư</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Trò chơi</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Người chơi đặt</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Kết quả</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số tiền cược</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số tiền thắng</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Thời gian đặt</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Hủy/trả thưởng</TableCell>
                                                    {(profile.isAdmin == true) ? (
                                                        <TableCell sx={{ padding: "10px" }}>Sửa</TableCell>
                                                    ) : null}
                                                </TableRow>
                                            </TableHead>
                                            {data != null ? (
                                                <TableBody>
                                                    {data?.slice((pages - 1) * 12, (pages - 1) * 12 + 12).map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.user?.username}
                                                                </TableCell>
                                                                {/*<TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																				{item?.user?.iduser}
																				</TableCell>*/}
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {Math.floor(item?.user?.money).toLocaleString("vi-VN")}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.sanh}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px",
                                                                        maxWidth: "500px"
                                                                    }}>
                                                                    {GetNameChoose(item?.state, item?.type, item?.sanh)}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {item?.id_bet && (
                                                                        <>
                                                                            {item?.sanh == "Greedy" && (
                                                                                <img
                                                                                    alt=""
                                                                                    src={require(`../game/Greedy/images/food_${item?.id_bet?.result}.png`)}
                                                                                    style={{ height: "25px" }}
                                                                                />
                                                                            )}
                                                                            {item?.sanh?.indexOf("Xóc đĩa") != -1 && (
                                                                                <div
                                                                                    className="history_xd"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        transform: "scale(0.9)"
                                                                                    }}>
                                                                                    {item?.id_bet?.result?.split(" ").map((item) => (
                                                                                        <div className={`a${item}`}></div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                            {item?.sanh?.indexOf("Tài xỉu") != -1 && (
                                                                                <div
                                                                                    className="history_xucsac"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        transform: "scale(2.5)"
                                                                                    }}>
                                                                                    {item?.id_bet?.result?.split(" ").map((item) => (
                                                                                        <div className={`n${item}`}></div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                            {(item?.sanh?.indexOf("Xổ số") != -1 ||
                                                                                item?.sanh === "XSMB" ||
                                                                                item?.sanh === "XSMT" ||
                                                                                item?.sanh === "XSMN") && (
                                                                                <>
                                                                                    {item?.id_bet?.dacbiet && (
                                                                                        <span
                                                                                            className="badge"
                                                                                            style={{ fontSize: "12px" }}
                                                                                            onClick={() => openPopup(item?.id_bet)}>
                                                                                            Kiểm tra
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {item?.sanh === "CatDog" && (
                                                                                <>{GetNameChoose(item?.id_bet?.result?.slice(-1), null, "CatDog").replace("Chọn ", "")}</>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.money.toLocaleString("vi-VN")}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.moneythang.toLocaleString("vi-VN")}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.status_bet === "Win" ? (
                                                                        <Button color="success">Thắng</Button>
                                                                    ) : item?.status_bet === "Lose" ? (
                                                                        <Button color="error">Thua</Button>
                                                                    ) : item?.status_bet === "Pending" ? (
                                                                        <Button color="warning">Chờ xổ</Button>
                                                                    ) : (
                                                                        <Button style={{ color: "#000" }}>Đã hủy</Button>
                                                                    )}
                                                                </TableCell>

                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {formatDate(new Date(item?.createdAt))}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {formatDate(new Date(item?.updatedAt))}
                                                                </TableCell>
                                                                {(profile.isAdmin == true) ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setShow(true);
                                                                                setLs(item);
                                                                            }}>
                                                                            Sửa
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <div>Đang cập nhật</div>
                                            )}
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 12) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    }

                    {isShow === true ? (
                        <>
                            <div className="modal">
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    <form onSubmit={handleSubmitLs}>
                                        <div className="modalinner">
                                            <div className="modalheader"> Sửa lịch sử </div>

                                            <div className="modalform">
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Người chơi: </div>
                                                    <div>
                                                        <b>{ls?.user?.username}</b>
                                                    </div>
                                                </div>
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Trò chơi: </div>
                                                    <div>
                                                        <b>{ls?.sanh}</b>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Lựa chọn: </div>
                                                    <TextField name="state" defaultValue={ls?.state} sx={{ width: "100%" }} type="text" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số tiền cược: </div>
                                                    <TextField name="money" defaultValue={ls?.money} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số tiền thắng: </div>
                                                    <TextField name="moneythang" defaultValue={ls?.moneythang} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Trạng thái: </div>
                                                    <div>
                                                        <select name="status_bet" value={ls?.status_bet} onChange={handleChangeStatus}>
                                                            <option value="Win" selected={ls?.status_bet === "Win"} style={{ color: "#14B8A6" }}>
                                                                Thắng
                                                            </option>
                                                            <option value="Lose" selected={ls?.status_bet === "Lose"} style={{ color: "#D14343" }}>
                                                                Thua
                                                            </option>
                                                            <option value="Pending" selected={ls?.status_bet === "Pending"} style={{ color: "#FFB020" }}>
                                                                Chờ xổ
                                                            </option>
                                                            <option value="Cancelled" selected={ls?.status_bet === "Cancelled"} style={{ color: "#eee" }}>
                                                                Đã hủy
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="item_btn_form">
                                                <div className="modalformcontrols">
                                                    <Button type="submit">XÁC NHẬN</Button>
                                                </div>
                                                <div className="modalformcontrols">
                                                    <Button onClick={() => setShow(false)}>ĐÓNG</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {isOpen && total ? (
                        <div className="popup-backdrop" style={{ maxWidth: "100%" }}>
                            <div className="popup-main" style={{ maxWidth: "500px", margin: "0 auto" }}>
                                <div className="popup-header" style={{ fontSize: "18px" }}>
                                    Phiên {total?.id_bet}
                                </div>
                                <div className="popup-content" style={{ padding: "0" }}>
                                    <table id="table-xsmb" className="table-result table table-bordered table-striped table-xsmb">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "10%" }}>ĐB</th>
                                                <td>
                                                    <span id="mb_prize_0" className="special-prize div-horizontal">
                                                        {total?.dacbiet}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>1</th>
                                                <td>
                                                    <span id="mb_prize_1" className="prize1 div-horizontal">
                                                        {total?.nhat}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>2</th>
                                                <td>
                                                    <span id="mb_prize_2" className="prize2 div-horizontal">
                                                        {total?.hai.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_3" className="prize2 div-horizontal">
                                                        {total?.hai.split(" ")[1]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>3</th>
                                                <td>
                                                    <span id="mb_prize_4" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_5" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_6" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_7" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[3]}
                                                    </span>
                                                    <span id="mb_prize_8" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[4]}
                                                    </span>
                                                    <span id="mb_prize_9" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[5]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>4</th>
                                                <td>
                                                    <span id="mb_prize_10" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_11" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_12" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_13" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[3]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>5</th>
                                                <td>
                                                    <span id="mb_prize_14" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_15" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_16" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_17" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[3]}
                                                    </span>
                                                    <span id="mb_prize_18" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[4]}
                                                    </span>
                                                    <span id="mb_prize_19" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[5]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>6</th>
                                                <td>
                                                    <span id="mb_prize_20" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_21" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_22" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[2]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>7</th>
                                                <td>
                                                    <span id="mb_prize_23" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_24" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_25" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_26" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[3]}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    onClick={closePopup}
                                    className="popup-close"
                                    style={{
                                        background: "#00b977",
                                        boxShadow: "none",
                                        textShadow: "none",
                                        fontSize: "18px"
                                    }}>
                                    Đóng
                                </button>
                            </div>
                        </div>
                    ) : null}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default HistoryBetAll;
