import { Outlet, Navigate } from "react-router";
import { LoginStatus } from "./hook/checkLg";
import axios from "axios";
import { useEffect, useState } from "react";
const PrivateRouteAdmin = () => {
    const [checking, setChecking] = useState(true);
    const [isAdmin, setIsAdmin] = useState(null);
    const [isCustomer, setIsCustomer] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");

            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }

            return config;
        },

        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setChecking(false);
                if (res.data.data.isAdmin == true || res.data.data.isSale == true) setIsAdmin(true);
                setIsCustomer(res.data.data.isCustomer);
                if (res.data.data.isAdmin || res.data.data.isCustomer || res.data.data.isSale) localStorage.setItem("profile", JSON.stringify(res.data.data));
            })
            .catch((res) => {
                setChecking(false);
                localStorage.removeItem("user");
                localStorage.removeItem("profile");
                localStorage.removeItem("data");
            });
    }, []);

    if (checking) {
        return (
            <>
                <div></div>
            </>
        );
    }
    return isAdmin ? <Outlet /> : isCustomer ? <Navigate to="/customer" /> : <Navigate to="/login" />;
};
export default PrivateRouteAdmin;
