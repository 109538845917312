import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, TextField, Pagination } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";

function Add() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        if (load === false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/payment/nap`, {})
                .then((res) => {
                    setData(res.data.data);
                    setData2(res.data.data);
                })
                .catch((err) => setLoad(false));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data2.filter((row) => {
                const a = row?.user?.username?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b = Number(row?.user?.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const c = row?.detail?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a || b || c;
            });
            setData(filteredRows);
            setPage(1);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };
    const [pages, setPage] = useState(1);
    const [searched, setSearched] = useState("");
    const handleChange = (e, page) => {
        setPage(page);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">Yêu cầu nạp tiền</div>
                                <div className="form_set">
                                    <Box>
                                        <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} placeholder="Tìm kiếm" />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>Tên tài khoản</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>ID USER</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Phương thức</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số tiền</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Khuyến mãi</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số dư</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Hành Động</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {data && (
                                                <TableBody>
                                                    {data?.slice((pages - 1) * 10, (pages - 1) * 10 + 10).map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.user?.username}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.user?.iduser}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.detail}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{Number(item?.money).toLocaleString("vi-VN")} </TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                    {item?.km ? Number(item?.km).toLocaleString("vi-VN") : "0"}
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                    <span>{item?.user?.money.toLocaleString("vi-VN")}</span>
                                                                </TableCell>
                                                                {item?.status_payment === "Pending" ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button type="text" color="warning">
                                                                            {item?.status_payment}
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                {item?.status_payment === "Success" ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button type="text" color="success">
                                                                            {item?.status_payment}
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                {item?.status_payment === "Deny" ? (
                                                                    <TableCell sx={{ padding: "10px" }}>
                                                                        <Button type="text" color="error">
                                                                            {item?.status_payment}
                                                                        </Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                <TableCell sx={{ padding: "10px" }}>
                                                                    {(profile.isAdmin == true) ? (
                                                                        <>
                                                                            <textarea
                                                                                type="text"
                                                                                name="note"
                                                                                value={item?.note}
                                                                                disabled={item?.status_payment !== "Pending"}
                                                                                onChange={(e) => setNote(e.target.value)}
                                                                                onKeyUp={(e) => setNote(e.target.value)}
                                                                            />
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setLoading(true);
                                                                                    const formData = {
                                                                                        id: item?._id,
                                                                                        status: "Accept",
                                                                                        note: note
                                                                                    };
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/payment/update`, formData)
                                                                                        .then((res) => {
                                                                                            swal("Cập nhật thành công", "", "success").then(() => window.location.reload());
                                                                                        })
                                                                                        .catch((err) => {
                                                                                            swal("Cập nhật thành công", "", "success").then(() => window.location.reload());
                                                                                        });
                                                                                }}
                                                                                disabled={item?.status_payment !== "Pending"}>
                                                                                Xác nhận
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setLoading(true);
                                                                                    const formData = {
                                                                                        id: item?._id,
                                                                                        status: "Deny",
                                                                                        note: note
                                                                                    };
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/payment/update`, formData)
                                                                                        .then((res) => {
                                                                                            swal("Cập nhật thành công", "", "success").then(() => window.location.reload());
                                                                                        })
                                                                                        .catch((err) => {
                                                                                            swal("Cập nhật thành công", "", "success").then(() => window.location.reload());
                                                                                        });
                                                                                }}
                                                                                disabled={item?.status_payment !== "Pending"}>
                                                                                Từ chối
                                                                            </Button>
                                                                        </>
                                                                    ) : (
                                                                        <div>Bạn không có quyền truy cập</div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 10) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default Add;
